import React from 'react'

import MasterPage from '../MasterPage'

// import ViajesImage1 from '../../images/viajes/a1.jpeg'
// import ViajesImage2 from '../../images/viajes/a2.jpeg'
// import ViajesImage3 from '../../images/viajes/a3.jpeg'
// import ViajesImage4 from '../../images/viajes/a4.jpeg'
// import ViajesImage5 from '../../images/viajes/a5.jpeg'
// import ViajesImage6 from '../../images/viajes/a6.jpeg'
// const ViajesImage = [ViajesImage1, ViajesImage2, ViajesImage3, ViajesImage4, ViajesImage5, ViajesImage6, ViajesImage7]

const Page = () => {
  // const [memory, setMemory] = React.useState(null)
  //
  // const showMemory = (type) => {
  //   setTimeout(() => {
  //     setMemory(null)
  //   }, 5000);
  //   switch (type) {
  //     case 'viaje': {
  //       const random = Math.floor((Math.random() * (ViajesImage.length + 1)))
  //       setMemory(ViajesImage[random])
  //       break;
  //     }
  //     default:
  //   }
  // }
  // return (
  //   <MasterPage>
  //     {memory && <PopUpMemory pic={memory} />}
  //     <h1>Este es tu regalito.</h1>
  //     <div className='dashboard'>
  //       <button onClick={() => showMemory('viaje')}>Un Viaje</button>
  //       <MemoryBox title='Comidas' />
  //       <MemoryBox title='Transportes' />
  //     </div>
  //   </MasterPage>
  // )
  return <div />
}


export default Page
